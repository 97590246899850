export default [
  {
    text: "Мои данные",
    link: { name: "LigaForumFirstShiftProfile" },
    key: "profile",
    disabled: false,
  },
  {
    text: "Выбор трека и команды",
    link: { name: "LigaForumFirstShiftTracks" },
    key: "tracks",
    disabled: false,
  },
  {
    text: "Информация",
    link: { name: "LigaForumFirstShiftInfo" },
    key: "info",
    disabled: false,
  },
];
