<template>
  <div class="collective-form">
    <Title
      :label="`Анкета «Лига форум»/ ${currentLabel}`"
      icon
      :application-info="currentCategoryData"
    />
    <div class="student-year__header">
      <TopSidebar :tabData="formattedLigaForumBar" />
    </div>
    <Comment
      class="student-year__comment"
      v-if="currentCategoryData?.comment_employee && currentStatusForComment"
      :comment="currentCategoryData?.comment_employee"
    />
    <router-view :currentProject="currentProject" type="First" />
  </div>
</template>

<script>
import Title from "@/components/Blocks/Title.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import TopSidebar from "@/components/Blocks/TopSidebar.vue";
import ligaForumFirstShiftBar from "@/enums/ligaForumFirstShiftBar";
import Comment from "@/components/Blocks/Comment.vue";
import tracksKeys from "@/enums/tracksKeys";

export default {
  name: "LigaForumFirstShiftForm",
  components: { Comment, TopSidebar, Title },
  data() {
    return {
      ligaForumFirstShiftBar,
      commentStatuses: [27, 28, 35, 40],
      tracksKeys,
    };
  },
  watch: {
    user() {
      this.setProjectEducationData();
    },
  },
  computed: {
    currentDirections() {
      return this.directions?.info
        ?.find(
          (category) =>
            category.liga_category_id ===
            (this.currentCategoryId ||
              this.currentCategoryData?.info_category?.id)
        )
        ?.info_directions?.data?.map((direction) => {
          return { id: direction.id, title: direction.title };
        });
    },
    currentDirectionsWithValidations() {
      let buffer = this.currentDirections.map((direction) => {
        const validation = this.directions.validation?.find(
          (validationRule) => {
            return (
              direction.id === validationRule.direction_id &&
              validationRule.type_ee ===
                this.user.info_educational_establishment?.type?.key &&
              validationRule.region_ids?.length > 0
            );
          }
        );
        return validation ? { ...direction, validation } : direction;
      });
      buffer = buffer?.filter((dir) => {
        dir.inArray = false;
        if ("validation" in dir) {
          dir.inArray =
            dir?.validation?.type_ee ===
              this.user.info_educational_establishment?.type?.key &&
            dir?.validation?.region_ids?.includes(
              this.user.education_region_id
            );
        }
        return dir.inArray;
      });
      return buffer;
    },
    formattedLigaForumBar() {
      let buffer = JSON.parse(JSON.stringify(this.ligaForumFirstShiftBar));

      return buffer.map((item) => {
        if (item.key === "tracks") {
          item.disabled = !this.disabledFields["isLFFirstShiftProfileFilled"];
        }
        if (item.key === "info") {
          item.disabled =
            !this.disabledFields["isLFFirstShiftProfileFilled"] ||
            !this.disabledFields["isLFFirstShiftTracksFilled"];
        }
        return item;
      });
    },
    currentProject() {
      return Object.values(this.projectsList)?.find((item) => {
        return this.currentCategoryProject
          ? item.id === this.currentCategoryProject
          : item.id === this.currentCategoryData?.info_project?.id;
      });
    },
    currentLabel() {
      return this.currentProject?.settings?.categories.find((item) => {
        return this.currentCategoryId
          ? item.category_id === this.currentCategoryId
          : item.category_id === this.currentCategoryData?.info_category?.id;
      })?.title;
    },
    currentStatusForComment() {
      return this.currentCategoryData?.status?.key
        ? this.commentStatuses.includes(this.currentCategoryData?.status?.key)
        : false;
    },
    filteredTracksKeys() {
      let buffer = this.tracksKeys;
      if (this.currentDirectionsWithValidations.length === 1) {
        buffer = this.tracksKeys.filter((key) => {
          return key !== "second_direction_id" && key !== "third_direction_id";
        });
      } else if (this.currentDirectionsWithValidations.length === 2) {
        buffer = this.tracksKeys.filter((key) => {
          return key !== "third_direction_id";
        });
      }
      return buffer;
    },

    ...mapGetters(["projectsList"]),
    ...mapState("user", ["disabledFields", "user"]),
    ...mapState([
      "currentCategoryData",
      "currentCategoryProject",
      "currentCategoryVersion",
      "currentCategoryId",
      "firstShiftStatementData",
    ]),
    ...mapGetters(["directions"]),
  },
  methods: {
    ...mapMutations(["setShiftStatementData"]),
  },
  mounted() {
    this.setShiftStatementData({
      key: "firstShiftStatementData",
      form: this.currentCategoryData?.info_liga_forum,
    });

    this.$store.commit("user/setDisabledData", [
      `isLFFirstShiftTracksFilled`,
      this.filteredTracksKeys?.every((item) => {
        if (this.firstShiftStatementData[item]) {
          return this.firstShiftStatementData[item];
        } else return false;
      }),
    ]);
  },
};
</script>

<style lang="scss"></style>
